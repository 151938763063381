'use client'
import Image from 'next/image'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

export default function SliderPiscina({ images }: { images: string[] }) {
  return (
    <div className="relative h-screen">
      <Swiper
        modules={[Autoplay, Pagination]}
        pagination={{
          el: '#fullpage-container-bullets',
          type: 'bullets',
          // bulletClass: "bg-red-800",

          bulletActiveClass: 'swiper--active',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + '' + '</span>'
          },
        }}
        className="relative [&_.swiper--active]:h-3 [&_.swiper--active]:w-3 [&_.swiper--active]:bg-[#B7A16D] [&_.swiper--active]:opacity-100 [&_.swiper-pagination-bullet]:h-3 [&_.swiper-pagination-bullet]:w-3"
        loop={true}
        speed={600}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          
          // pauseOnMouseEnter: true
        }}
      >
        <div className="min-w-screen absolute top-0 z-50 flex h-screen min-h-screen w-screen flex-col items-center justify-center gap-12 	bg-[#1A2238] bg-opacity-60 px-4 py-12 md:justify-center md:gap-16 ">
          <div className="flex flex-col gap-12 md:flex-col-reverse">
            <div
              id="fullpage-container-bullets"
              className="mb-0 flex items-center justify-center gap-3"
            />
            <p className="max-w-[33ch]  text-center  font-montserrat text-sm font-light leading-5 text-white md:max-w-[60ch] md:text-3xl md:font-medium md:leading-8">
              Piscina Climatizada al aire libre con Jacuzzi
            </p>
          </div>
        </div>
        {images.map((image, index) => (
          <SwiperSlide key={image}>
            <div className="relative h-screen w-screen">
              <Image
                src={image}
                alt=""
                loading="eager"
                fill
                className=" -z-10 h-screen w-screen object-cover"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
