'use client'
import Slider from '@/components/slider'
import { BedroomGroup } from '@/data/bedrooms'
import React, { useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'

import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/styles.css'

type GroupedBedrooms = {
  [key: string]: BedroomGroup
}
export default function Bedrooms({ bedrooms }: { bedrooms: GroupedBedrooms }) {
  const [gallery, setGallery] = useState<string[]>([])
  return (
    <>
      {Object.keys(bedrooms).map((key) => (
        <Slider
          setGallery={setGallery}
          key={key}
          id={key}
          name={key}
          slidesData={bedrooms[key].rooms}
          characteristics={bedrooms[key].characteristics}
          imgPosition={bedrooms[key].imgPosition}
        />
      ))}
      <Lightbox
        open={gallery.length > 0}
        close={() => setGallery([])}
        plugins={[Thumbnails, Zoom]}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        slides={[
          ...gallery.map((img) => ({
            src: img,
          })),
        ]}
        styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" }}}

      />
    </>
  )
}
