'use client'
import { useOnScreen } from '@/lib/hooks/useOnScreen'
import logo from '@/public/images/logo.svg'
import Image from 'next/image'
import { useState } from 'react'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'

import Lightbox from 'yet-another-react-lightbox'
import Video from 'yet-another-react-lightbox/plugins/video'

import 'swiper/css'
import 'swiper/css/pagination'
import 'yet-another-react-lightbox/styles.css'

const VIDEO = '/images/Video Aereo/Anatolia Suites - Ostende - Argentina.mp4'

export default function MainCarrousel({ images }: { images: string[] }) {
  const { ref } = useOnScreen()
  const [showVideo, setShowVideo] = useState(true)

  return (
    <>
      <div className="relative h-screen" ref={ref}>
        <Swiper
          modules={[Autoplay, Pagination]}
          pagination={{
            el: '#fullpage-container-bullets',
            type: 'bullets',
            // bulletClass: "bg-red-800",

            bulletActiveClass: 'swiper--active',
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '">' + '' + '</span>'
            },
          }}
          className="relative [&_.swiper--active]:h-3 [&_.swiper--active]:w-3 [&_.swiper--active]:bg-[#B7A16D] [&_.swiper--active]:opacity-100 [&_.swiper-pagination-bullet]:h-3 [&_.swiper-pagination-bullet]:w-3"
          loop={true}
          speed={600}
          autoplay={{
            delay: 3000,
            // disableOnInteraction: false,
            // pauseOnMouseEnter: true,
          }}
        >
          <div className="min-w-screen absolute top-0 z-50 flex h-screen min-h-screen w-screen flex-col items-center justify-center gap-12 	bg-[#1A2238] bg-opacity-60 px-4 py-12 md:justify-center md:gap-16">
            <Image
              src={logo}
              alt="Logo"
              width={36}
              height={36}
              className="mt-32 w-28 xl:w-32 2xl:mt-12 2xl:w-44"
              priority
            />
            <div className="flex flex-col gap-8 md:flex-col-reverse">
              <div
                id="fullpage-container-bullets"
                className="mb-0 flex items-center justify-center gap-2"
              />
              <p className="max-w-[33ch]  text-center  font-montserrat text-sm font-light leading-5 text-white md:max-w-[60ch] md:text-xl md:font-medium md:leading-8 xl:text-2xl">
                A poca distancia del centro comercial, las hermosas playas de
                nuestra costa y todo el glamour de la famosa vida nocturna de la
                Ciudad de Pinamar.
              </p>
            </div>
          </div>
          {images.map((image, index) => {
            return (
              <SwiperSlide key={image}>
                <div className="h-screen w-screen">
                  <Image
                    src={image}
                    alt=""
                    loading="eager"
                    fill
                    className=" -z-10 h-screen w-screen object-cover"
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
      <Lightbox
        plugins={[Video]}
        open={showVideo}
        close={() => setShowVideo(false)}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        slides={[
          {
            type: "video",
            width: 1280,
            height: 720,
            
            sources:  [
              {
                src: VIDEO,
                type: "video/mp4",
                
              }
            ],
          },
        ]}
        video={{
          autoPlay: true,
          controls: false,
          loop: true,
          muted: true,
          playsInline: true,
          preload: "auto",

        }}
        render={{
          buttonPrev:  () => null,
          buttonNext:  () => null,
        }}
        styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}

      />
    </>
  )
}
