'use client'
import { Button } from '@/components/ui/button'
import { Bedroom, BedroomGroup } from '@/data/bedrooms'
import { cn } from '@/lib/utils'
import file from '@/public/icons/file.svg'
import Image from 'next/image'
import Link from 'next/link'
import { Dispatch, useEffect, useState } from 'react'
import { useSwiper } from 'swiper/react'

export default function BedroomCard({
  bedroom,
  setGallery,
}: {
  bedroom: Bedroom & Omit<BedroomGroup, 'rooms'> & { name: string }
  setGallery: Dispatch<React.SetStateAction<string[]>>
}) {
  const [selectedButton, setSelectedButton] = useState<number>(0)
  const swiper = useSwiper()
  useEffect(() => {
    swiper.on('slideChange', () => {
      setSelectedButton(swiper.activeIndex);
    });
    return () => {
      swiper.off('slideChange');
    };
  }, [swiper]);

  return (
    <div
      className={cn(
        'mx-auto grid max-w-[1440px] items-center justify-center gap-4 md:grid-cols-2 '
      )}
    >
      <div
        className={cn(
          // bg-white rounded-xl shadow border border-zinc-300
          'z-50  order-last  mx-auto flex max-w-[90%]  -translate-y-24 flex-col gap-3 rounded-xl border border-zinc-300 bg-white px-5  py-9  shadow xs:max-w-[45ch] md:z-auto md:max-w-none md:translate-y-0 md:rounded-none md:border-0   md:py-24 md:shadow-none',
          {
            'md:order-last md:mr-12 md:items-end md:pl-6 md:text-right ':
              bedroom.imgPosition === 'left',
            ' md:order-first md:ml-12 md:items-start md:pr-6':
              bedroom.imgPosition === 'right',
          }
        )}
      >
        <div className="flex items-center justify-between break-words">
          <div className="flex flex-col gap-2">
            <h1 className="break-all text-2xl font-bold text-[#B7A16D] xs:text-3xl lg:text-5xl ">
              {bedroom.name}
            </h1>
            <p className="font-roboto text-sm font-light text-slate-800 xs:text-base lg:text-3xl">
              {bedroom.subtitle}
            </p>
          </div>
          <Button
            variant={'ghost'}
            className="h-8 gap-2 rounded-md px-3 md:hidden lg:h-9 lg:px-4 lg:py-2 lg:text-sm "
            onClick={() => {
              setGallery([bedroom.plan])
            }}
          >
            <Image src={file} alt="Link de los planos" className="h-6 w-6 " />
            Plano
          </Button>
        </div>
        <p className=" pb-6 text-justify font-roboto text-xs font-normal   leading-normal text-neutral-500 md:max-w-[70ch] md:leading-snug lg:text-sm">
          {bedroom.description}
        </p>
        <div
          className={cn('flex max-w-[70ch] flex-wrap gap-1',  {
            'md:justify-end': bedroom.imgPosition === 'left',
          })}
        >
          {bedroom.characteristics &&
            bedroom.characteristics.map((characteristic, index) => (
              <Button
                key={index}
                onClick={() => {
                  setSelectedButton(index)
                  swiper.slideTo(index)
                }}
                variant={selectedButton === index ? 'selected' : 'default'}
                className="h-8 rounded-md px-3 text-xs lg:h-9 lg:px-4 lg:py-2 lg:text-sm"
              >
                {characteristic}
              </Button>
            ))}
          {bedroom.plan && (
            <Button
              variant={'ghost'}
              className="hidden h-8 gap-2 rounded-md px-3 text-xs md:inline-flex lg:h-9 lg:px-4 lg:py-2 lg:text-sm"
              onClick={() => {
                setGallery([bedroom.plan])
              }}
            >
              <Image
                src={file}
                alt="Link de los planos"
                className="h-4 w-4 lg:h-6 lg:w-6 "
              />
              Plano
            </Button>
          )}
        </div>
      </div>
      <div
        className={cn('group relative  flex w-screen md:w-full', {
          'justify-end': bedroom.imgPosition === 'right',
        })}
      >
        <div className=" relative h-[440px] w-full md:max-w-[708px]">
          <Image
            src={bedroom.images[0]}
            fill
            alt={bedroom.name}
            className={cn('object-cover', {
              'md:object-right': bedroom.imgPosition === 'right',
              'md:object-left': bedroom.imgPosition === 'left',
            })}
            sizes="(max-width: 768px) 100vw, 75vw, 1200px"
          />
          <div className="absolute flex h-full w-full items-center justify-center transition-all duration-300 hover:bg-[#1A2238]/80 ">
            <Button
              variant={'outline'}
              size={'lg'}
              className="hidden text-white group-hover:flex"
              onClick={() => {
                setGallery(bedroom.images)
              }}
            >
              Ver más
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
