'use client'
import { Autoplay, HashNavigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'

import BedroomCard from '@/app/_components/bedroom-card'
import { Bedroom } from '@/data/bedrooms'
import { Dispatch, use, useEffect, useRef, useState } from 'react'
import 'swiper/css'
import 'swiper/css/pagination'
import Modal from './ui/Modal'

export default function Slider({
  id,
  slidesData,
  name,
  characteristics,
  imgPosition,
  setGallery,
}: {
  id: string
  slidesData: Bedroom[]
  characteristics: string[]
  name: string
  imgPosition: 'left' | 'right'
  setGallery: Dispatch<React.SetStateAction<string[]>>

}) {
  return (
    <div className="relative" id={id}>
      <Swiper
        modules={[Autoplay, Pagination]}
        pagination={{
          el: '#containerForBullets22',
          type: 'bullets',
          // bulletClass: "bg-red-800",

          bulletActiveClass: 'swiper--active',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + '' + '</span>'
          },
        }}
        className="relative [&_.swiper--active]:h-3 [&_.swiper--active]:w-3 [&_.swiper--active]:bg-[#B7A16D] [&_.swiper--active]:opacity-100 [&_.swiper-pagination-bullet]:h-3 [&_.swiper-pagination-bullet]:w-3  [&_.swiper-pagination-bullets]:mb-32  [&_.swiper-pagination-bullets]:gap-4"
        loop={true}
        // speed={600}
        // autoplay={{
        //   delay: 2000,
        //   disableOnInteraction: false,
        //   pauseOnMouseEnter: true,
        // }}
      >
        {slidesData.map((bedroom, i) => {
          return (
            <SwiperSlide key={id + bedroom.id}>
              <BedroomCard
                setGallery={setGallery}
                
                bedroom={{
                  ...bedroom,
                  name,
                  characteristics,
                  imgPosition,
                }}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>

    </div>
  )
}
