'use client'
import { Input } from '@/components/ui/input'
import Image from 'next/image'
import { useFormState, useFormStatus } from 'react-dom'
import { handleFormSubmit } from '../actions/handleFormSubmit'
import { cn } from '@/lib/utils'
import SubmitButton from './submit-button'

const initialState = {
  message: '',
  status: 'idle',
}
export default function Formulario() {
  const { pending } = useFormStatus()
  const [state, formAction] = useFormState(handleFormSubmit, initialState)

  return (
    <div className="mt-32 flex h-screen w-full" id="Reservar">
      <div className="relative hidden h-full max-h-[800px] w-1/2 bg-cover bg-center md:block ">
        <Image
          src="/images/img-anatolia/03-Ambientes/01-ambientes.jpg"
          alt="Consultar"
          fill
          quality={100}
          priority
          className="object-cover"
          sizes="(max-width: 768px) 100vw, 768px"
        />
      </div>
      <div className="mx-auto flex w-full max-w-md flex-col justify-center px-12 md:w-1/2 md:max-w-none ">
        <h1 className="mb-4 text-4xl font-bold text-[#B7A16D]">Consultar</h1>
        <h2 className="mb-8 font-roboto text-2xl font-light ">DEPARTAMENTO</h2>

        <form
          action={formAction}
          className="space-y-6 font-roboto text-base font-light leading-normal relative"
        >
          <div>
            <Input name="name" placeholder="Nombre y Apellido" required/>
          </div>
          <div>
            <Input type="email" name="email" placeholder="Email" required  />
          </div>
          <div>
            <Input type="tel" name="tel" placeholder="Tel/Contacto" />
          </div>
          <div>
            <Input
              type="message"
              className="h-32"
              name="message"
              placeholder="Mensaje"
              required
            />
          </div>
          <p aria-live="polite" className="sr-only">
            {state?.status}
          </p>
          <SubmitButton />

          <p className={cn("font-inter text-sm ", state.status === 'success' ? 'text-green-500' : 'text-red-500')}>
            {state?.message}
          </p>

        </form>
      </div>
    </div>
  )
}
